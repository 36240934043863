import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';
import { useLanguageQuery, useTranslation } from 'next-export-i18n';
import type { TableColumn } from 'react-data-table-component';
import dayjs from 'dayjs';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Payment from '@mui/icons-material/Payment';
import Print from '@mui/icons-material/Print';
import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

import { useRestaurantContext } from '@/contexts/restaurant';
import { IDinerFeeOption } from '@/views/OrdersTableView/types';
import { getCurrencyWithAmount } from '@/common/utility';
import { restrictRoles } from '@/constants/roleRestrictions';
import { useUserContext } from '@/contexts/user';
import CopyToClipboard from '@/common/CopyToClipboard';
import StatusChip from '@/components/PaymentLink/StatusChip';
import type { IPayment, IRefundConfigItem } from '.';
import { PaymentRecordType } from '.';

type ColumnType = TableColumn<IPayment> & { key?: string };

interface IUseColumns {
    refundConfigMap: { [key: string]: IRefundConfigItem };
    refundHandler: (data: IPayment) => void;
    filteredData?: IPayment[];
    setPrintDoc: Dispatch<SetStateAction<IPayment | null>>;
}

export const useColumns = ({ refundConfigMap, refundHandler, filteredData, setPrintDoc }: IUseColumns) => {
    const { t } = useTranslation('common');
    const [query] = useLanguageQuery();
    const lang = query ? query.lang : 'en';
    const { restaurant } = useRestaurantContext();
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('lg'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { user } = useUserContext();

    const showGrossTip = restaurant?.posAccess?.pos_vendor_data?.tipsSubmitMode === 'gross';

    const showQlubDinerFee =
        !restaurant?.restaurant_country?.config?.dinerFeeVisibility ||
        (!restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideAll) &&
            !restaurant?.restaurant_country?.config?.dinerFeeVisibility?.includes(IDinerFeeOption.HideOrders));

    const showForex = restaurant?.config?.enableForexBadge || false;
    const mobileMustColumns: Array<ColumnType> = [
        {
            name: t('Table Name'),
            selector: (row: IPayment) => row.tableName,
            compact: tablet,
            key: 'tableName',
            omit: !mobile,
            allowOverflow: false,
        },
        {
            name: t('Bill Amount'),
            selector: (row: IPayment) =>
                getCurrencyWithAmount(row.billAmount, row.currencySymbol, row.currencyCode, true),
            compact: tablet,
            key: 'billAmount',
            omit: !mobile,
        },
        {
            name: t('Paid Amount'),
            selector: (row: IPayment) =>
                row.paidAmount
                    ? getCurrencyWithAmount(row.paidAmount, row.currencySymbol, row.currencyCode, true)
                    : '-',
            compact: tablet,
            key: 'paidAmount',
            omit: !mobile,
        },
    ];

    const refundColumn: Array<ColumnType> = [
        {
            name: t('Refund'),
            cell: (row: IPayment) => (
                <Button
                    disabled={
                        refundConfigMap[row.gatewayVendor || '']?.refundType === 'disabled' ||
                        row.type === PaymentRecordType.Refund ||
                        row.type === PaymentRecordType.Discount ||
                        row.type === PaymentRecordType.Voucher ||
                        row.type === PaymentRecordType.Loyalty ||
                        row.type === PaymentRecordType.NonQlubUser
                    }
                    onClick={() => refundHandler(row)}
                    sx={{
                        textTransform: 'none',
                        color: '#A9A9A9',
                        margin: '0',
                        fontSize: '1em',
                        marginRight: '1em',
                    }}
                    startIcon={<RefreshOutlined />}
                >
                    {!tablet && t('refund')}
                </Button>
            ),
            omit: mobile,
            center: true,

            wrap: true,
            minWidth: '130px !important',
        },
    ];
    const mustShowColumnKeys: string[] = ['orderId', 'status'];
    const columns: Array<ColumnType> = [
        {
            name: t('Print'),
            cell: (row: IPayment) => {
                return (
                    <IconButton
                        color="primary"
                        size="small"
                        onClick={() => {
                            setPrintDoc(row);
                        }}
                        disabled={row.type === PaymentRecordType.Discount || row.type === PaymentRecordType.Rejected}
                    >
                        <Print />
                    </IconButton>
                );
            },
            center: true,
            key: 'print',
            grow: 0,
        },
        ...(!tablet
            ? [
                  {
                      name: t('Table ID'),
                      cell: (row: IPayment) => {
                          const [tooltipOpen, setTooltipOpen] = useState(false);
                          return (
                              <Tooltip
                                  title={row.tableId}
                                  placement="right"
                                  arrow
                                  sx={{
                                      color: '#000000',
                                  }}
                                  onClick={() => {
                                      setTooltipOpen(true);
                                  }}
                                  onTouchMove={() => {
                                      setTooltipOpen(true);
                                  }}
                                  onClose={() => {
                                      setTooltipOpen(false);
                                  }}
                                  open={tooltipOpen}
                              >
                                  <Typography
                                      sx={{
                                          fontSize: '13px',
                                          color: '#000000',
                                          whiteSpace: 'nowrap',
                                          overflow: 'hidden',
                                          textOverflow: 'ellipsis',
                                          width: '70px',
                                      }}
                                  >
                                      {row.tableId}
                                  </Typography>
                              </Tooltip>
                          );
                      },
                      compact: tablet,
                      center: true,
                      key: 'tableId',
                      omit: mobile,
                      wrap: true,
                  },
              ]
            : []),

        {
            name: t('Table Name'),
            cell: (row: IPayment) => {
                const [tooltipOpen, setTooltipOpen] = useState(false);
                return (
                    <Tooltip
                        title={row.tableName}
                        placement="right"
                        arrow
                        sx={{
                            color: '#000000',
                        }}
                        onClick={() => {
                            setTooltipOpen(true);
                        }}
                        onClose={() => {
                            setTooltipOpen(false);
                        }}
                        open={tooltipOpen}
                        leaveDelay={2000}
                    >
                        <Typography
                            sx={{
                                fontSize: '13px',
                                color: '#000000',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                width: '70px',
                            }}
                        >
                            {row.tableName}
                        </Typography>
                    </Tooltip>
                );
            },
            center: true,
            key: 'tableName',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Status'),
            cell: (row: IPayment) => {
                return (
                    <Badge
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        color="secondary"
                        badgeContent={
                            showForex && row.forexValue ? (
                                <>
                                    <Payment sx={{ width: '10px', height: '10px', marginRight: '5px' }} />
                                    <Typography
                                        sx={{
                                            height: '24px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            fontSize: '8px',
                                            lineHeight: '140%',
                                            textAlign: 'center',
                                            letterSpacing: '0.25px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {t('Forex')}
                                    </Typography>
                                </>
                            ) : (
                                0
                            )
                        }
                        showZero={false}
                    >
                        <StatusChip
                            status={
                                row.status === 'rejected'
                                    ? 'rejected'
                                    : row.type === PaymentRecordType.Refund
                                    ? 'refunded'
                                    : row.paid
                                    ? 'paid'
                                    : 'partially_paid'
                            }
                        />
                    </Badge>
                );
            },
            center: true,
            key: 'status',
            omit: mobile,
            minWidth: '140px',
        },
        {
            name: t('Time'),
            selector: (row: IPayment) => row.time,
            center: true,
            key: 'time',
            omit: mobile,
            minWidth: '0px !important',
        },
        {
            name: t('Date'),
            selector: (row: IPayment) => (lang === 'ja' ? dayjs(row.date).format('YYYY/MM/DD') : row.date),
            center: true,
            key: 'date',
            omit: mobile,
        },
        {
            name: t('Date & Time'),
            selector: (row: IPayment) =>
                lang === 'ja' ? dayjs(row.date).format('YYYY/MM/DD') : `${row.date} ${row.time}`,
            center: true,
            key: 'dateandtime',
            omit: mobile,
            minWidth: '130px !important',
        },
        {
            name: t('Order ID'),
            cell: (row: IPayment) => {
                const [tooltipOpen, setTooltipOpen] = useState(false);

                return (
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <CopyToClipboard value={row.orderId} />
                        <Tooltip
                            title={row.orderId}
                            placement="right"
                            arrow
                            sx={{
                                color: '#000000',
                            }}
                            onClick={() => {
                                setTooltipOpen(true);
                            }}
                            onTouchMove={() => {
                                setTooltipOpen(true);
                            }}
                            onClose={() => {
                                setTooltipOpen(false);
                            }}
                            open={tooltipOpen}
                        >
                            <Typography
                                sx={{
                                    fontSize: '13px',
                                    color: '#000000',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    width: '70px',
                                }}
                            >
                                {row.orderId}
                            </Typography>
                        </Tooltip>
                    </Box>
                );
            },
            center: true,
            key: 'orderId',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Method'),
            selector: (row: IPayment) => row.cardBrand,
            center: true,
            key: 'cardBrand',
            omit: mobile,
            wrap: false,
        },
        {
            name: t('Last 4 Digits'),
            selector: (row: IPayment) => row.last4,
            center: true,
            key: 'last4',
            omit: mobile,
            wrap: true,
        },
        ...(restaurant?.restaurant_country?.code === 'sa'
            ? [
                  {
                      name: t('MADA'),
                      selector: (row: IPayment) => (row.madaCard === 'mada' ? t('yes') : t('no')),
                      center: true,
                      key: 'madaCard',
                      omit: mobile,
                  },
              ]
            : []),
        {
            name: t('Bill Amount'),
            selector: (row: IPayment) =>
                getCurrencyWithAmount(row.billAmount, row.currencySymbol, row.currencyCode, true),
            center: true,
            key: 'billAmount',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Paid Amount'),
            selector: (row: IPayment) =>
                row.paidAmount
                    ? getCurrencyWithAmount(row.paidAmount, row.currencySymbol, row.currencyCode, true)
                    : '-',
            center: true,
            key: 'paidAmount',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Tip Amount'),
            selector: (row: IPayment) => {
                if (showGrossTip) {
                    return row.grossTipAmount
                        ? getCurrencyWithAmount(row.grossTipAmount, row.currencySymbol, row.currencyCode, true)
                        : '-';
                }
                return row.netTipAmount
                    ? getCurrencyWithAmount(row.netTipAmount, row.currencySymbol, row.currencyCode, true)
                    : '-';
            },
            center: true,
            key: 'tipAmount',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Booked Amount'),
            selector: (row: IPayment) =>
                row.customerPaidExcludingDinerFee
                    ? getCurrencyWithAmount(
                          row.customerPaidExcludingDinerFee,
                          row.currencySymbol,
                          row.currencyCode,
                          true,
                      )
                    : '-',

            center: true,
            key: 'bookedAmount(paid+tip)',
            omit: mobile,
            wrap: true,
        },
        ...(showQlubDinerFee
            ? [
                  {
                      name: t('Qlub Diner Fee'),
                      selector: (row: IPayment) =>
                          row.customerCommission
                              ? getCurrencyWithAmount(
                                    row.customerCommission,
                                    row.currencySymbol,
                                    row.currencyCode,
                                    true,
                                )
                              : '-',
                      center: true,
                      key: 'customerCommission',
                      omit: mobile,
                      wrap: true,
                  },
              ]
            : []),
        {
            name: t('Total Amount'),
            selector: (row: IPayment) =>
                row.totalAmount
                    ? getCurrencyWithAmount(row.totalAmount, row.currencySymbol, row.currencyCode, true)
                    : '-',
            center: true,
            key: 'totalAmount',
            omit: mobile,
            wrap: true,
        },
        {
            name: t('Payment Type'),
            cell: (row: IPayment) => (
                <StatusChip
                    status={
                        row.type === PaymentRecordType.Discount
                            ? 'qlub-discount'
                            : row.type === PaymentRecordType.Voucher
                            ? 'qlub-voucher'
                            : row.type === PaymentRecordType.Loyalty
                            ? 'qlub-loyalty'
                            : 'bill'
                    }
                />
            ),
            center: true,
            key: 'paymentType',
            omit: mobile,
            width: '110px',
        },
        ...(restrictRoles.REFUND.includes(user.userData.role) ? refundColumn : []),
    ];

    return useMemo(() => {
        const configPaymentKeys = restaurant?.config.vendorPaymentKeys || [];
        const countryPaymentKeys = restaurant?.restaurant_country?.config?.vendorPaymentKeys || [];
        const configPaymentKeysLength = configPaymentKeys.length > 0;
        const countryPaymentKeysLength = countryPaymentKeys.length > 0;

        if (configPaymentKeysLength || countryPaymentKeysLength) {
            const vendorPaymentKeys = configPaymentKeysLength ? configPaymentKeys : countryPaymentKeys;

            const vendorPaymentColumns = columns.filter((column) => {
                const found = vendorPaymentKeys?.find(
                    (o) => o === column.key || mustShowColumnKeys.includes(column.key || ''),
                );
                return found;
            });
            return [
                ...((mobile && mobileMustColumns) || []),
                ...vendorPaymentColumns,
                ...(restrictRoles.REFUND.includes(user.userData.role) ? refundColumn : []),
            ];
        }

        if (mobile) {
            const mobileColumns = columns.filter((column) => {
                const found = mobileMustColumns.find((o) => o.key === column.key);
                return !found;
            });

            return [...mobileMustColumns, ...mobileColumns];
        }
        return columns;
    }, [lang, tablet, mobile, columns, mobileMustColumns, filteredData]);
};
